import React, { Fragment, useContext, useEffect, useState } from "react";

import {
  FaTelegram,
  FaTimes,
  FaTwitter,
  FaHome,
  FaBullhorn,
  FaBars,
  FaArrowRight,
  FaEnvelope,
  FaUserPlus,
  FaArrowCircleUp,
  FaExternalLinkAlt,
  FaBell,
  FaUser,
  FaDollarSign,
  FaPlus,
  FaDiscord
} from "react-icons/fa";
import { Popover, Transition, Menu } from "@headlessui/react";
import { Context } from "../Store";
import { useWeb3React } from "@web3-react/core";
import { injected, walletconnect } from "../libs/ConnectorWeb3";
import { useEagerConnect, useInactiveListener } from "../hooks/hooks";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import { getAppByChainId, getEnv } from "../libs/Env";
import { NavLink } from "react-router-dom";
import useDarkMode from "./useDarkMode";
import { BigNumber } from "bignumber.js";
import { BsWallet2 } from "react-icons/bs";
import { RiFileCopy2Line } from "react-icons/ri";
import { toast } from "react-toastify";
import { getSimpleAddress } from "../libs/WebooLib";

const solutions = [
  {
    name: "Home",
    description: "Farm Homepage.",
    href: "/",
    icon: FaHome,
  },
  {
    name: "Earn",
    description: "Earn",
    href: "/",
    icon: FaUser,
  },
  {
    name: "Vote",
    description: "Shill Features.",
    href: "/vote",
    icon: FaDollarSign,
  },
];
const resources = [
  {
    name: "Telegram Chat",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "https://t.me/SakaiVault",
    icon: FaTelegram,
  },
  {
    name: "Telegram Channel",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "https://t.me/SakaiVault",
    icon: FaBullhorn,
  },
  {
    name: "Twitter",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "https://twitter.com/SakaiVault",
    icon: FaTwitter,
  },
  {
    name: "Discord",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "https://discord.gg/HHq9uEXvr9",
    icon: FaDiscord,
  },
];

const dapps = [
  {
    name: "Register dApps",
    description:
      "Register dApps",
    href: "/register-dapps",
    icon: FaUserPlus,
  },
  {
    name: "Create Ads",
    description:
      "Create Ads",
    href: "/create-ads",
    icon: FaUserPlus,
  },
  {
    name: "Create Airdrop Event",
    description:
      "Create Airdrop",
    href: "/create-ads",
    icon: FaUserPlus,
  },
];

const shill = [
  {
    name: "Shill List",
    description:
      "Shill List",
    href: "/shill",
    icon: FaBullhorn,
  },
  {
    name: "Create Shill",
    description:
      "Create Shill",
    href: "/create-shill",
    icon: FaPlus,
  },
  {
    name: "History Activity",
    description: "Shill Features.",
    href: "/history",
    icon: FaDollarSign,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Header = () => {
  const [state, dispatch] = useContext(Context);
  const [colorTheme, setTheme] = useDarkMode();
  const [balanceWeboo, setBalanceWeboo] = useState(0);
  const [balanceBNB, setBalanceBNB] = useState(0);

  // useEffect(()=>{
  //   const button = document.querySelector('#menu-button');
  //   const menu = document.querySelector('#menu');
  //   button.addEventListener('click', () => {
  //     menu.classList.toggle('hidden');
  //   });
  // },[])

  useEffect(async () => {
    if (state.web3 && state.account) {
      const bnb = await state.web3.eth.getBalance(state.account);
      const newBNB = new BigNumber(state.web3.utils.fromWei(bnb));
      setBalanceBNB(newBNB.toFormat(4));

      const token = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ERC20_ABI,
        state.web3.utils.toHex(getAppByChainId(state.chainId).TOKEN_ADDRESS)
      );
      const weboo = await token.methods
        .balanceOf(state.web3.utils.toHex(state.account))
        .call();
      const newWeboo = new BigNumber(state.web3.utils.fromWei(weboo));
      setBalanceWeboo(newWeboo.toFormat(4));
    }
  }, [state]);
  const getSimpleAddress = (str) => {
    return str.substr(0, 6) + "..." + str.substr(str.length - 3, str.length);
  };

  const getSimpleAddressMobile = (str) => {
    return str.substr(0, 3) + "..." + str.substr(str.length - 3, str.length);
  };

  const doLogout = async () => {
    dispatch({
      type: "LOGOUT_WALLET_CONNECT",
      payload: true,
    });
  };

  return (
    <>
      <Popover className="relative bg-gray-100 dark:bg-gray-900">
        <div className="px-4 mx-auto lg:px-28 md:px-16 sm:px-8">
          <div className="flex items-center justify-between py-4 border-b-2 border-gray-100 dark:border-gray-900 md:justify-start md:space-x-4">
            <div className="flex items-center justify-start gap-2">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-500 font-medium mr-6 flex items-center gap-1"
                    : "text-gray-900 dark:text-white mr-6 font-medium flex items-center gap-1"
                }
              >
                <img src="/images/logo_b.png" className="hidden md:block lg:block" height="24px" alt="" />
                <img src="/images/logo-dark.png" className="block md:hidden lg:hidden" height="24px" alt="" />
              </NavLink>
            </div>
            <div className="flex items-center gap-2 -my-2 -mr-2 md:hidden">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button as="div">
                  <BtnWalletConnect>
                    <button
                      // onClick={() => doLogout()}
                      className={
                        "rounded-lg text-white bg-green-600 border-2 border-green-500 px-1 py-1 md:px-3 md:py-2 lg:px-3 lg:py-2"
                      }
                    >
                      {getSimpleAddressMobile(state.account ? state.account : "")} ({state.chainId})
                      {/*{state.account}*/}
                    </button>
                  </BtnWalletConnect>
                </Menu.Button>
                <Menu.Items className="absolute left-0 w-56 px-4 py-4 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg dark:divide-gray-800 dark:bg-gray-900 ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <>
                        <div className="pb-2 text-sm font-bold text-black dark:text-white">
                          Balance
                        </div>
                        <div className="flex items-center justify-start gap-2 py-2 border-0">
                          <img
                            src="./images/icon-reward/weboo.svg"
                            className="w-5 h-5"
                            alt=""
                          />
                          <span className="text-sm text-black dark:text-white">
                            {balanceWeboo} WEBOO
                          </span>
                        </div>
                        <div className="flex items-center justify-start gap-2 py-2 border-0">
                          <img
                            src="./images/icon-reward/bnb.svg"
                            className="w-5 h-5"
                            alt=""
                          />
                          <span className="text-sm text-black dark:text-white">
                            {balanceBNB} BNB
                          </span>
                        </div>
                        <div className="flex pt-3">
                          <button
                            className="flex items-center gap-2 text-sm font-bold text-green-500"
                            type="button"
                            onClick={() => doLogout()}
                          >
                            <FaArrowRight className="w-3 h-3" /> Disconnect
                          </button>
                        </div>
                      </>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Menu>

              {colorTheme === "light" ? (
                <div className="p-1 text-black bg-white rounded-lg dark:bg-gray-800 dark:text-white">
                  <svg
                    onClick={() => setTheme("light")}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                    />
                  </svg>
                </div>
              ) : (
                <div className="p-1 text-white bg-gray-900 rounded-lg">
                  <svg
                    onClick={() => setTheme("dark")}
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                    />
                  </svg>
                </div>
              )}

              <Popover.Button className="inline-flex items-center justify-center p-1 text-gray-400 bg-white rounded-md dark:bg-gray-800 hover:black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <FaBars className="w-6 h-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-8 md:flex">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-500 font-medium"
                    : "dark:text-white text-gray-900 font-medium"
                }
              >
                Earn
              </NavLink>
              <NavLink
                to="/vote"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-500 font-medium"
                    : "dark:text-white text-gray-900 font-medium"
                }
              >
                Vote
              </NavLink>
              <NavLink
                to="/staking-history"
                className={({ isActive }) =>
                  isActive
                    ? "text-green-500 font-medium"
                    : "dark:text-white text-gray-900 font-medium"
                }
              >
                Staking History
              </NavLink>
              <a
                href="https://dex.sakaivault.io/swap?networkId=56&inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x43B35e89d15B91162Dea1C51133C4c93bdd1C4aF"
                className="font-medium text-gray-900 dark:text-white"
              >
                Buy Sakai
              </a>
              <a
                href="https://docs.sakaivault.io/features/staking"
                className="font-medium text-gray-900 dark:text-white"
              >
                Docs
              </a>
            </Popover.Group>
            <div className="items-center justify-end hidden gap-1 md:flex md:flex-1 lg:w-0">
              <div className="py-2 md:p-2">
                <NavLink to="/dashboard" className="px-1 py-1 text-green-600 bg-white border border-green-600 rounded-lg md:px-3 md:py-2 lg:px-3 lg:py-3">
                  Open Dashboard
                </NavLink>
              </div>
              <div className="py-2 md:p-2">
                <BtnWalletConnect>
                  <button
                    onClick={() => doLogout()}
                    className={
                      "rounded-lg text-white bg-green-600 border-2 border-green-600 px-1 py-1 md:px-3 md:py-2 lg:px-3 lg:py-2 flex items-center gap-2"
                    }
                  >
                    {state.account !== "" ? (
                      <img src={'https://api.webooswap.com/sakai-dao/profile?address=' + state.account} className="border rounded-full" height="30px" width="30px" alt="profile" />
                    ) : (
                      <img src={'https://api.webooswap.com/sakai-dao/profile?address=0x'} className="border rounded-full" height="30px" width="30px" alt="profile" />
                    )}
                    {getSimpleAddress(state.account ? state.account : "")}  ({state.chainId})
                    {/*{state.account}*/}
                  </button>
                </BtnWalletConnect>
              </div>

              <div className="">
                {colorTheme === "light" ? (
                  <div className="px-2 py-2 text-black bg-white border-2 border-gray-800 rounded-lg dark:bg-gray-800 dark:text-white">
                    <svg
                      onClick={() => setTheme("light")}
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                      />
                    </svg>
                  </div>
                ) : (
                  <div className="px-2 py-2 text-white bg-gray-900 border-2 border-gray-800 rounded-lg">
                    <svg
                      onClick={() => setTheme("dark")}
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="relative inset-x-0 top-0 p-2 transition origin-top-right transform md:hidden"
          >
            <div className="z-20 bg-white divide-y-2 rounded-lg shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-gray-50 dark:divide-gray-900">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img src="/images/logo_b.png" alt="" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md dark:bg-gray-900 hover:black hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Close menu</span>
                      <FaTimes className="w-6 h-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {solutions.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          isActive
                            ? "flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                            : "flex items-center p-3 -m-3 rounded-md hover:bg-gray-50"
                        }
                      >
                        <item.icon
                          className="flex-shrink-0 w-6 h-6 text-green-500"
                          aria-hidden="true"
                        />
                        <span className="ml-3 text-base font-medium text-gray-900 dark:text-gray-400">
                          {item.name}
                        </span>
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
              <div className="px-5 py-6 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  {resources.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="text-base font-medium text-gray-900 hover:text-gray-700 dark:text-gray-400"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div>
                  <div className="py-2 md:p-2">
                    {/*<ModalWallet />*/}
                    {/*<ModalWeb3 />*/}
                  </div>

                  <div className="flex items-center justify-between gap-2">
                    <BtnWalletConnect>
                      <button
                        onClick={() => doLogout()}
                        className={
                          "rounded-lg text-white bg-green-600 px-3 py-2"
                        }
                      >
                        {getSimpleAddress(state.account ? state.account : "")}
                        {/*{state.account}*/}
                      </button>
                    </BtnWalletConnect>
                    {colorTheme === "light" ? (
                      <div className="px-2 py-2 text-black bg-white border-2 border-gray-800 rounded-lg dark:bg-gray-800 dark:text-white">
                        <svg
                          onClick={() => setTheme("light")}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                          />
                        </svg>
                      </div>
                    ) : (
                      <div className="px-2 py-2 text-white bg-gray-900 border-2 border-gray-800 rounded-lg">
                        <svg
                          onClick={() => setTheme("dark")}
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      <ModalWeb3 />
    </>
  );
};

export const Footer = () => {
  return (
    <>
      <section className="px-4 pt-20 pb-12 transition-all duration-500 bg-gray-100 dark:bg-gray-800 lg:px-28 md:px-16 linear">
        <footer className="pb-2 mx-auto">
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 lg:gap-0 gap-y-6">
            <div className="">
              <div className="flex items-center justify-start gap-1 mb-5">
                <img src="/images/logo_b.png" height="28" alt="" />
              </div>
              <div className="space-y-4">
                <p className="text-black dark:text-white">
                  Sakai Staking Platform
                </p>
                <a href="https://sakai.com/token">
                  <p className="flex items-center py-4 font-semibold black dark:text-white">
                    Readmore
                    <FaArrowRight className="w-6 h-6 ml-2" />
                  </p>
                </a>
                <div
                  className="flex items-center mb-4 space-x-2 font-medium text-gray-900 title-font lg:mb-0 md:mb-0"
                  style={{ cursor: "pointer" }}
                >
                  <a
                    href="https://t.me/SakaiVault"
                    className="px-3 py-3 text-black bg-white rounded-md dark:bg-gray-900 dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-telegram"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z" />
                    </svg>
                  </a>
                  <a
                    href="https://t.me/SakaiVault"
                    className="px-3 py-3 text-black bg-white rounded-md dark:bg-gray-900 dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-megaphone-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-11zm-1 .724c-2.067.95-4.539 1.481-7 1.656v6.237a25.222 25.222 0 0 1 1.088.085c2.053.204 4.038.668 5.912 1.56V3.224zm-8 7.841V4.934c-.68.027-1.399.043-2.008.053A2.02 2.02 0 0 0 0 7v2c0 1.106.896 1.996 1.994 2.009a68.14 68.14 0 0 1 .496.008 64 64 0 0 1 1.51.048zm1.39 1.081c.285.021.569.047.85.078l.253 1.69a1 1 0 0 1-.983 1.187h-.548a1 1 0 0 1-.916-.599l-1.314-2.48a65.81 65.81 0 0 1 1.692.064c.327.017.65.037.966.06z" />
                    </svg>
                  </a>
                  <a
                    href="https://twitter.com/SakaiVault"
                    className="px-3 py-3 text-black bg-white rounded-md dark:bg-gray-900 dark:text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-twitter"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="pl-1 md:pl-12 lg:pl-10">
              <h1 className="mb-5 text-2xl font-semibold text-black title-font dark:text-white">
                Navigation
              </h1>
              <ul className="space-y-2 text-black dark:text-gray-200">
                <li>
                  <a href="https://sakai.com/token">What is Sakai Token?</a>
                </li>
                <li>
                  <a href="https://sakai.com/documents">Documents</a>
                </li>
                <li>
                  <a href="https://sakai.com/team">Teams</a>
                </li>
              </ul>
            </div>
            <div className="pl-1 md:pl-12 lg:pl-10">
              <h2 className="mb-5 text-2xl font-semibold text-black title-font dark:text-white">
                Support
              </h2>
              <ul className="space-y-2 text-black dark:text-gray-200">
                <li>
                  <a href="https://t.me/SakaiVault">Telegram Group</a>
                </li>
                <li>
                  <a href="https://t.me/SakaiVault">
                    Telegram Channel
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/SakaiVault">Twitter</a>
                </li>
                <li>
                  <a href="https://sakai.com/privacy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="https://sakai.com/privacy">
                    Disclaimer & Risk
                  </a>
                </li>
              </ul>
            </div>
            <div className="pl-1 md:pl-12 lg:pl-10">
              <h2 className="mb-5 text-2xl font-semibold text-black title-font dark:text-white">
                Keep in touch
              </h2>
              <ul className="space-y-5 text-black dark:text-gray-200">
                <li>
                  <a href="mailto:mail@mail.com" className="flex">
                    <FaEnvelope className="w-6 h-6 mr-2" /> For Support <br />
                    mail@mail.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mx-auto border-color">
            <div className="mt-8">
              <hr className="border-gray-500 dark:border-gray-900" />
            </div>
            <div className="container flex flex-col items-center pt-12 mx-auto space-y-5 lg:flex-row lg:space-y-0">
              <nav className="flex flex-col items-center justify-center text-black dark:text-white lg:flex-row">
                <p>Copyright © 2022 Sakai. All rights reserved.</p>
              </nav>
            </div>
          </div>
        </footer>
      </section>
    </>
  );
};

export const Modal = ({
  title = "",
  children,
  isOpen = false,
  onClose = (e) => { },
}) => {
  return (
    <>
      {isOpen ? (
        <>
          <div className="fixed inset-0 z-50 flex justify-center pt-20 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-full mx-auto my-6 md:w-1/3 lg:w-1/3 lg:max-w-3xl">
              <div className="relative flex flex-col w-full bg-white border-0 shadow-lg outline-none rounded-3xl focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-greenGray-200">
                  <h3 className="text-lg font-semibold text-black dark:text-white">
                    {title}
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-lg font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                    onClick={() => onClose(false)}
                  >
                    <FaTimes color={"black"} />
                  </button>
                </div>

                <div className="relative flex-auto p-6">{children}</div>
              </div>
            </div>
          </div>
          <div className="fixed inset-0 z-40 bg-black opacity-25" />
        </>
      ) : null}
    </>
  );
};

export const ToastContentTx = ({
  title,
  closeToast,
  txHash,
  chainId
}) => {
  return (
    <div>
      {title} <br />
      <a href={getAppByChainId(chainId).URL_EXPLORER + "/tx/" + txHash} target="_blank" style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#04A759' }}>
        View Detail
      </a>
    </div>
  )
}

export const Toast = ({
  title = "",
  children,
  isOpen = false,
  onClose = (e) => { },
  duration = 60000,
}) => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    setShowToast(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!showToast) return;
    setTimeout(() => {
      setShowToast(false);
      onClose(true);
    }, duration);
  }, [showToast]);

  return (
    <>
      {showToast ? (
        <>
          <div className="fixed inset-0 z-50 flex justify-center pt-5 overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-full px-4 mx-auto md:w-2/4 lg:w-2/4">
              <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                <div className="flex items-start justify-between p-3 bg-green-600 border-b border-green-600 border-solid rounded-t">
                  <h3 className="flex items-center text-sm font-bold text-white rounded-lg">
                    <FaBell className="w-4 h-4 mr-1" /> {title}
                  </h3>
                  <button
                    className="float-right p-1 ml-auto text-sm font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-2 focus:outline-none"
                    onClick={() => {
                      setShowToast(false);
                      onClose(false);
                    }}
                  >
                    <FaTimes className="text-white" />
                  </button>
                </div>

                <div className="relative flex-auto p-3 text-xs">{children}</div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const CopyAddress = ({ addr }) => {
  const [address, setAddress] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);

  const getSimpleAddress = (str) => {
    return str.substr(0, 6) + "..." + str.substr(str.length - 3, str.length);
  };

  useEffect(() => {
    setAddress(getSimpleAddress(addr))

  }, [addr]);

  const onCopy = () => {
    var TempText = document.createElement("input");
    TempText.value = addr;
    document.body.appendChild(TempText);
    TempText.select();

    document.execCommand("copy");
    document.body.removeChild(TempText);
    // if ('clipboard' in navigator) {
    //   navigator.clipboard.writeText(addr)
    // } else {
    //   document.execCommand('copy', true, addr);
    // }

    toast.success("Address Copied " + getSimpleAddress(addr))
    // setIsToastOpen(true)
  }

  return (
    <div className="flex flex-row items-center justify-content-center">
      <a href={"https://bscscan.com/address/" + addr}>
        {address}
      </a>
      &nbsp;
      <button className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800" onClick={(e) => { e.preventDefault(); onCopy() }}>
        Copy
      </button>
      <Toast title="Success" isOpen={isToastOpen} onClose={(e) => { setIsToastOpen(false) }}>
        Copied to Clipboard
      </Toast>
    </div>
  )
}

export const CopyButton = ({ text }) => {
  const [address, setAddress] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);

  const getSimpleAddress = (str) => {
    return str.substr(0, 6) + "..." + str.substr(str.length - 3, str.length);
  };

  useEffect(() => {
    setAddress(getSimpleAddress(text))

  }, [text]);

  const onCopy = () => {
    var TempText = document.createElement("input");
    TempText.value = text;
    document.body.appendChild(TempText);
    TempText.select();

    document.execCommand("copy");
    document.body.removeChild(TempText);
    // if ('clipboard' in navigator) {
    //   navigator.clipboard.writeText(addr)
    // } else {
    //   document.execCommand('copy', true, addr);
    // }
    toast.success('Successfully Copied!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    // setIsToastOpen(true)
  }

  return (
    <div className="flex flex-row items-center justify-content-center">
      &nbsp;
      <button className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800" onClick={(e) => { e.preventDefault(); onCopy() }}>
        <RiFileCopy2Line /> Copy
      </button>
      <Toast title="Success" isOpen={isToastOpen} onClose={(e) => { setIsToastOpen(false) }}>
        Copied to Clipboard
      </Toast>
    </div>
  )
}

/** Bugs wallet connect */
export const ModalWallet = () => {
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error,
  } = useWeb3React();

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = React.useState();
  React.useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  const [isSelectWalletOpen, setIsSelectWalletOpen] = useState(false);
  const onClick = (p) => {
    try {
      activate(p, undefined, true);
    } catch (ex) {
      console.log(ex);
    }
  };
  const getSimpleAddress = (str) => {
    return str.substr(0, 6) + "..." + str.substr(str.length - 3, str.length);
  };
  useEffect(() => {
    setIsSelectWalletOpen(false);
  }, [active]);

  const logout = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Do you want to Log Out?")) {
      deactivate();
    }
  };
  return (
    <>
      {!active ? (
        <button
          onClick={() => setIsSelectWalletOpen(true)}
          className={"rounded-lg text-white bg-green-600 px-3 py-2"}
        >
          Connect Wallet
        </button>
      ) : (
        <button
          onClick={() => logout()}
          className={"rounded-full text-white bg-green-600 px-3 py-2"}
        >
          {getSimpleAddress(account)}
        </button>
      )}
      <Modal
        title={"Select Wallet"}
        isOpen={isSelectWalletOpen}
        onClose={(e) => setIsSelectWalletOpen(e)}
      >
        <div className={"grid grid-cols-2 gap-y-4 gap-x-2"}>
          {/*<CardWebooWallet onClick={(p) => onClick(p)} />*/}
          <CardMetamask onClick={(p) => onClick(p)} />
          <CardWalletConnect onClick={(p) => onClick(p)} />
        </div>
      </Modal>
    </>
  );
};

export const ModalWeb3 = () => {
  const [isSelectWalletOpen, setIsSelectWalletOpen] = useState(false);
  const [state, dispatch] = useContext(Context);

  useEffect(async () => {
    setIsSelectWalletOpen(state.isTriggerConnectWallet);
    if (state.web3) {
      const chainId = await state.web3.eth.getChainId();
    }
    if (state.web3Logout) doLogout();
  }, [state]);

  useEffect(async () => {
    try {
      await onClickMetamask();
    } catch (e) {
      console.error(e);
    }
  }, []);

  const onClickMetamask = async () => {
    const providerOptions = {
      /* See Provider Options Section */
      injected: {
        display: {
          // logo: "https://cdn.webooswap.com/storage/file/file_202201221104014935_1642849441.png",
          name: "Injected",
          description: "Connect with the provider in your Browser",
        },
        package: null,
      },
    };
    const web3Modal = new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions, // required
      theme: "dark",
    });
    const provider = await web3Modal.connectTo("injected");
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();
    await validateChain(chainId);
    providerEvent(provider, web3);
    dispatch({
      type: "SET_WEB3",
      payload: {
        web3: web3,
        web3modal: web3Modal,
      },
    });
    dispatch({ type: "SET_ACCOUNT", payload: accounts[0] });
  };

  const onClickWC = async () => {
    const providerOptions = {
      /* See Provider Options Section */
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            56: "https://bsc-dataseed1.defibit.io",
          },
        },
      },
    };
    const web3Modal = new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions, // required
      theme: "dark",
    });
    const provider = await web3Modal.connectTo("walletconnect");
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();
    await validateChain(chainId);
    providerEvent(provider, web3);
    dispatch({
      type: "SET_WEB3",
      payload: {
        web3: web3,
        web3modal: web3Modal,
      },
    });
    dispatch({ type: "SET_ACCOUNT", payload: accounts[0] });
  };

  const providerEvent = (provider, web3) => {
    provider.on("accountsChanged", (accounts) => {
      dispatch({ type: "SET_ACCOUNT", payload: accounts[0] });
    });

    provider.on("chainChanged", async (chainId) => {
      let chain_id = chainId;
      if (web3.utils.isHex(chainId)) chain_id = web3.utils.hexToNumber(chainId);
      await validateChain(chain_id);
    });

    provider.on("connect", (info) => {
      console.log(info);
    });

    provider.on("disconnect", (error) => {
      console.log(error);
      dispatch({ type: "SET_ACCOUNT", payload: null });
    });

    setTimeout(async () => {
      dispatch({ type: "STOREHASH" });
    }, [2000]);
  };

  const validateChain = async (chainId) => {
    const chain_id = chainId;
    // if(state.web3.utils.isHex(chain_id)) chain_id = state.web3.utils.hexToNumber(chain_id)
    // alert(state.web3.utils.isHex(chain_id))
    if (!getEnv().ALLOWED_CHAIN_ID.includes(chain_id)) {
      alert("Wrong Network");
      console.log(chainId)
      dispatch({ type: "LOGOUT_WALLET_CONNECT", payload: false });
    }

    dispatch({ type: "SET_CHAIN_ID", payload: chainId });
  };

  const doLogout = async () => {
    // await state.web3modal.clearCachedProvider();
    dispatch({
      type: "SET_WEB3",
      payload: {
        web3: null,
        web3modal: null,
      },
    });
    dispatch({ type: "SET_CHAIN_ID", payload: null });
    dispatch({ type: "LOGOUT_WALLET_CONNECT", payload: false });

    setTimeout(async () => {
      dispatch({ type: "STOREHASH" });
    }, [2000]);
  };

  return (
    <>
      <Modal
        title={"Select Wallet"}
        isOpen={isSelectWalletOpen}
        onClose={(e) => setIsSelectWalletOpen(e)}
      >
        <div className={"flex flex-col gap-y-3"}>
          {/*<CardWebooWallet onClick={(p) => onClickMetamask()} />*/}
          <CardMetamask onClick={(p) => onClickMetamask()} />
          <CardWalletConnect onClick={(p) => onClickWC()} />
        </div>
      </Modal>
    </>
  );
};

export const ModalWeb3Test = () => {
  const onConnect = async () => {
    const providerOptions = {
      /* See Provider Options Section */
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          rpc: {
            97: "https://data-seed-prebsc-1-s1.binance.org:8545",
          },
        },
      },
    };

    const web3Modal = new Web3Modal({
      // network: "mainnet", // optional
      cacheProvider: false, // optional
      providerOptions, // required
      theme: "dark",
    });

    const instance = await web3Modal.connectTo("walletconnect");

    const provider = new ethers.providers.Web3Provider(instance);
    // const signer = provider.getSigner();
    // alert("tes")
  };
  return (
    <button
      onClick={() => onConnect()}
      className={"rounded-lg text-white bg-green-600 px-3 py-2"}
    >
      Connect Wallet
    </button>
  );
};

export const CardMetamask = ({ onClick = (e) => { } }) => {
  return (
    <button
      className={
        "items-center border-2 rounded-full text-center flex flex-row justify-between py-2 hover:border-3 hover:border-red-600 px-8 gap-y-2"
      }
      onClick={() => onClick(injected)}
    >
      <div className={"text-sm font-bold"}>Metamask</div>
      <img
        alt={"Metamask"}
        src={
          "./images/metamask.png"
        }
        style={{
          width: 30,
          height: 30,
        }}
      />
    </button>
  );
};

export const CardWebooWallet = ({ onClick = (e) => { } }) => {
  return (
    <button
      className={
        "items-center border-2 rounded-full text-center flex flex-row justify-between py-2 hover:border-3 hover:border-red-600 px-8 gap-y-2"
      }
      onClick={() => onClick(injected)}
    >
      <div className={"text-sm font-bold"}>Weboo Wallet</div>
      <img
        alt={"WebooWallet"}
        src={
          "https://cdn.webooswap.com/storage/file/file_202201221439036521_1642862343.svg"
        }
        style={{
          width: 30,
          height: 30,
        }}
      />
    </button>
  );
};

export const CardWalletConnect = ({ onClick = (p) => { } }) => {
  return (
    <button
      className={
        "items-center border-2 rounded-full text-center flex flex-row justify-between py-2 hover:border-3 hover:border-red-600 px-8 gap-y-2"
      }
      onClick={() => onClick(walletconnect)}
    >
      <div className={"text-sm font-bold"}>Wallet Connect</div>
      <img
        alt={"Wallet Connect"}
        src={
          "./images/wallet_connect.png"
        }
        style={{
          width: 30,
          height: 30,
        }}
        loading="lazy"
      />
    </button>
  );
};

export const LoadingWeboo = () => {
  return (
    <div className={"flex flex-col items-center justify-center animate-pulse"}>
      <img
        src={
          "/images/logo-dark.png"
        }
        style={{
          width: 300,
          height: 100,
        }}
        className={"mr-2"}
        alt={"Dex Name"}
      />
      Loading...
    </div>
  );
};

export const NotFound = () => {
  return (
    <div className={"flex flex-col"}>
      <img
        src={
          "https://cdn.webooswap.com/storage/file/file_202201221439036521_1642862343.svg"
        }
        style={{
          width: 100,
          height: 100,
        }}
        className={"mr-2"}
        alt={"Dex Name"}
      />
      Not Found.
    </div>
  );
};

export const ChainIconCircle = ({
  icon1,
  icon2,
  size = 80,
  borderWidth = 3,
}) => {
  return (
    <div className={"flex flex-row items-center justify-center"}>
      <img
        src={icon1}
        style={{
          width: size,
          height: size,
        }}
        className={"rounded-full p-0.5 border-green-600 border-" + borderWidth}
        alt={"Weboo"}
      />
      <span
        className={"rounded-full p-1 bg-green-600"}
        style={{
          position: "absolute",
          borderWidth: borderWidth,
        }}
      />
      <img
        src={icon2}
        style={{
          width: size,
          height: size,
        }}
        className={"rounded-full p-0.5 border-green-600 border-" + borderWidth}
        alt={"Weboo"}
      />
    </div>
  );
};

export const BtnWalletConnect = ({ children, isRounded = false }) => {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    if (!state.web3) {
      // dispatch({
      //   type: "CONNECT_WALLET",
      //   payload: true,
      // });
    }
  }, []);

  const onConnectWallet = () => {
    dispatch({
      type: "CONNECT_WALLET",
      payload: true,
    });
  };

  return (
    <>
      {!state.isConnectWallet ? (
        <button
          onClick={() => onConnectWallet()}
          className={"flex items-center justify-center w-full text-white bg-green-600 border-2 border-green-600 px-2 py-2 md:px-2 md:py-2 lg:px-2 lg:py-2 gap-2" + (isRounded ? ' rounded-full' : ' rounded-lg')}
        >
          <BsWallet2 />
          Connect
        </button>
      ) : (
        children
      )}
    </>
  );
};



export const CircleLoading = ({ size }) => {
  return (
    <div className={"animate-pulse"}>
      <div className={`bg-red-300 rounded-full w-${size} h-${size}`} />
    </div>
  );
};

export const ModalTxHash = ({
  txHash = null,
  isReceipt = false,
  onReceiptClose = () => { },
}) => {
  const [state, dispatch] = useContext(Context);
  const [storeHash, setStoreHash] = useState(null);
  const [showModal, setShowModal] = useState(true);
  const [showReceipt, setShowReceipt] = useState(false);
  const [urlExplorer, setUrlExplorer] = useState("https://bscscan.com");

  useState(async () => {
    if (state && state.web3) {
      setUrlExplorer(getAppByChainId(state.chainId).URL_EXPLORER);
      // setUrlExplorer("https://bscscan.com");
      setStoreHash(state.web3.storeHash);
    }
  }, [state]);

  useEffect(() => {
    if (!txHash) return setShowModal(false);
    else {
      setTimeout(() => {
        setShowModal(true);
      }, 2000);
    }
  }, [txHash]);

  useEffect(() => {
    if (!isReceipt) return;
    setShowReceipt(true);
  }, []);

  return (
    <>
      {showModal ? (
        <>
          <Modal
            title={"Transaction Submitted"}
            isOpen={showModal}
            onClose={() => setShowModal(false)}
          >
            <div className={"flex flex-col items-center gap-y-3"}>
              <FaArrowCircleUp size={100} />
              <a
                href={urlExplorer + "/tx/" + txHash}
                target={"_blank"}
                rel={"noreferrer"}
                className={
                  "no-underline font-bold flex flex-row gap-x-3 justify-center items-center text-black dark:text-white"
                }
              >
                <span>View On Explorer</span>
                <FaExternalLinkAlt />
              </a>
            </div>
          </Modal>
        </>
      ) : null}
      {/*<Toast isOpen={showReceipt} title={"Transaction Receipt"} onClose={()=>{setShowReceipt(false);onReceiptClose()}}>*/}
      {/*  <a href={urlExplorer+"/tx/"+txHash} target={"_blank"} rel={"noreferrer"} className={"underline font-bold flex text-sm flex-row gap-x-3"}>*/}
      {/*    <span>View On Explorer</span>*/}
      {/*    <FaExternalLinkAlt />*/}
      {/*  </a>*/}
      {/*</Toast>*/}
    </>
  );
};


export const Empty = ({ text }) => {
  return (
    <div className={"w-full text-3xl flex items-center text-center align-center flex-col"}>
      <span>{text}</span>
    </div>
  );
};

export const LastTxAccount = ({ loop = 3, txHash }) => {
  const [state, dispatch] = useContext(Context);
  const [listTx, setListTx] = useState([]);

  const getTx = async () => {
    if (state.account) {
      setListTx([])

      await state.web3.eth.getTransactionCount(state.account)
        .then((b) => {
          if (b < loop) loop = b;
          let tmp = [];
          // for(let i=1;i<=loop;i++){
          //   state.web3.eth.getBlock(b-i).then((Block)=>{
          //     console.log(Block)
          //     state.web3.eth.getTransactionFromBlock(Block.number, 10000).then(txBlock=>{
          //       console.log(txBlock)
          //     })
          //     // state.web3.eth.getTransactionFromBlock(Block.hash, 1)
          //     // .then(res=>console.log(res));
          //     // tmp.push(Block)
          //   })
          // }
          setListTx(tmp)
        })
      // await state.web3.eth.getPastLogs({
      //   address: state.account,
      // }).then(console.log);


    }
  }

  useEffect(() => {
    getTx()
  }, [state, txHash]);

  return (
    <div className="text-right">
      <ul>
        {listTx.map((v, i, a) => {
          return (
            <li key={v.hash}>
              <small>
                <a href={getAppByChainId(state.chainId).URL_EXPLORER + "/tx/" + v.hash} target={"_blank"} style={{ fontWeight: 'bold' }}>
                  View Detail {getSimpleAddress(v.hash)}
                </a>
              </small>
            </li>
          )
        })}
      </ul>
    </div>
  )
}