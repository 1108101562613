import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {CopyButton, Footer, Header} from "../components/sakai-ui";
import { Tab } from '@headlessui/react'
import { Context } from "../Store";
import {
    RiChatPollLine,
    RiExchangeLine,
    RiFileCopyLine,
    RiGovernmentLine,
    RiTimer2Line,
    RiWallet2Fill
} from "react-icons/ri";
import {getAppByChainId, getEnv} from "../libs/Env";
import {
    amountFormat,
    amountFormatFromWei,
    checkIsNeedApprove,
    getSimpleAddress,
    getTokenBalance
} from "../libs/WebooLib";
import { toast } from "react-toastify";
import { FaSpinner } from 'react-icons/fa';
import { BigNumber } from "bignumber.js";
import axios from "axios";

export default function Home() {
    const [tokenBalance, setTokenBalance] = React.useState(0)
    const [stakedBalance, setStakedBalance] = React.useState(0)
    const [isNeedApproveStake, setIsNeedApproveStake] = React.useState(true)
    const [amountStake, setAmountStake] = React.useState(0)
    const [amountUnstake, setAmountUnstake] = React.useState(0)
    const [minimumStake, setMinimumStake] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false)
    const [stakingAddress,setStakingAddress] = React.useState("")
    const [tokenAddress,setTokenAddress] = useState("")
    const [urlToken, setUrlToken] = React.useState("")
    const [urlStaking, setUrlStaking] = React.useState("")
    const [reffCode, setReffCode] = React.useState(null)
    const [reffUpline, setReffUpline] = React.useState(null)
    const [dayStake, setDayStake] = React.useState(0)
    const [estimationTax, setEstimationTax] = React.useState(0)
    const [rewardOfReferrer, setRewardOfReferrer] = React.useState(0)
    const [totalRewardOfReferrer, setTotalRewardOfReferrer] = React.useState(0)
    const [state] = useContext(Context);
    const textAreaRef = useRef(null);
    const initialization = () => {
        const contract = async () => {
            try {
                const _tokenBalance = await getTokenBalance(state, state.account, getAppByChainId(state.chainId).TOKEN_ADDRESS)
                setTokenBalance(_tokenBalance)
                const _stakedbalance = await getTokenBalance(state, state.account, getAppByChainId(state.chainId).STAKING_ADDRESS)
                setStakedBalance(_stakedbalance)

                const _isNeedApprove = await checkIsNeedApprove(
                    state,
                    state.account,
                    getAppByChainId(state.chainId).TOKEN_ADDRESS,
                    getAppByChainId(state.chainId).STAKING_ADDRESS
                )
                setIsNeedApproveStake(_isNeedApprove)


                const stakingContract = await new state.web3.eth.Contract(
                    getAppByChainId(state.chainId).STAKING_ABI,
                    getAppByChainId(state.chainId).STAKING_ADDRESS
                );
                const _minimumStake = await stakingContract.methods.minimumStakeForVote().call()
                setMinimumStake(_minimumStake)
                const _dayStake = await stakingContract.methods.getTotalDaysStake(
                    state.web3.utils.toHex(state.account)
                ).call()
                setDayStake(_dayStake);
                const _estimationTax = await stakingContract.methods.estimateTaxPercentEarlyUnstake(
                    state.web3.utils.toHex(state.account)
                ).call()
                const _percent = parseFloat(_estimationTax[0]) / parseFloat(_estimationTax[1]) * 100
                setEstimationTax(_percent)

                const _rewardOfReferrer = await stakingContract.methods.dividendOfReferrer(
                    // state.web3.utils.toHex(state.account)
                    state.web3.utils.toHex('0xfbbf9873206613d88ca6724c7e4f4ea30ff84cd1')
                ).call()
                setRewardOfReferrer(_rewardOfReferrer)

                const _totalRewardOfReferrer = await stakingContract.methods.rewardsFromReferrer(
                    // state.web3.utils.toHex(state.account)
                    state.web3.utils.toHex('0xfbbf9873206613d88ca6724c7e4f4ea30ff84cd1')
                ).call()
                setTotalRewardOfReferrer(_totalRewardOfReferrer)

            } catch (e) {
                console.error(e)
            }

        }
        contract().then((res) => {

        })
        setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS)
        setStakingAddress(getAppByChainId(state.chainId).STAKING_ADDRESS)
        setUrlToken(getAppByChainId(state.chainId).URL_EXPLORER+"/token/"+getAppByChainId(state.chainId).TOKEN_ADDRESS)
        setUrlStaking(getAppByChainId(state.chainId).URL_EXPLORER+"/token/"+getAppByChainId(state.chainId).STAKING_ADDRESS)
        axios.get("https://api.sakaivault.io/api/wallet/0xfbbf9873206613d88ca6724c7e4f4ea30ff84cd1")
            .then(res => {
                setReffCode(res.data.refCode)
                axios.get('https://api.sakaivault.io/api/refcode/'+res.data.refCode).then(res => {
                    setReffUpline(res.data.wallet)
                })
            })
    }

    const handleApproveStaking = async () => {
        const token = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).ERC20_ABI,
            getAppByChainId(state.chainId).TOKEN_ADDRESS
        );
        setIsLoading(true)
        await token.methods.approve(
            getAppByChainId(state.chainId).STAKING_ADDRESS,
            state.web3.utils.toWei(tokenBalance.multipliedBy(2).toString())
        ).send({ from: state.account })
            .then(async (receipt) => {
                toast.success('Approve success')
                setIsLoading(false)
                initialization()
            }).catch((err) => {
                toast.error(err.message)
                setIsLoading(false)
            })
    }

    const handleStake = async () => {
        const token = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).STAKING_ABI,
            getAppByChainId(state.chainId).STAKING_ADDRESS
        );
        setIsLoading(true)
        const referrer = reffUpline ? reffUpline : "0x0000000000000000000000000000000000000000"
        await token.methods.stake(
            state.web3.utils.toWei(amountStake.toString()),
            state.web3.utils.toHex(referrer) // referral
        ).send({ from: state.account })
            .then(async (receipt) => {

                axios.post(getAppByChainId(state.chainId).API_URL + "/api/stake", {
                    type: "STAKE",
                    amount: state.web3.utils.toWei(amountStake.toString()),
                    txHash: receipt.transactionHash,
                    address: state.account,
                    referrer: referrer
                }).then((res) => {
                    console.log("Success Sync Stake With API")
                }).catch((err) => {
                    console.error(err)
                }).finally(() => {
                    toast.success('Staking success')
                    setIsLoading(false)
                    initialization()
                })
            }).catch((err) => {
                toast.error(err.message)
                setIsLoading(false)
            })
    }

    const handleUnstake = async () => {
        const token = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).STAKING_ABI,
            getAppByChainId(state.chainId).STAKING_ADDRESS
        );
        setIsLoading(true)
        await token.methods.unstake(
            state.web3.utils.toWei(amountUnstake.toString())
        ).send({ from: state.account })
            .then(async (receipt) => {
                const referrer = reffUpline ? reffUpline : "0x0000000000000000000000000000000000000000"
                axios.post(getAppByChainId(state.chainId).API_URL + "/api/stake", {
                    type: "UNSTAKE",
                    amount: state.web3.utils.toWei(amountUnstake.toString()),
                    txHash: receipt.transactionHash,
                    address: state.account,
                    referrer: referrer
                }).then((res) => {
                    console.log("Success Sync Stake With API")
                }).catch((err) => {
                    console.error(err)
                }).finally(() => {
                    toast.success('Staking success')
                    setIsLoading(false)
                    initialization()
                })
            }).catch((err) => {
                toast.error(err.message)
                setIsLoading(false)
            })
    }

    const isButtonStakeEnabled = () => {
        if (!amountStake) return false;
        const _amountStake = new BigNumber(amountStake).multipliedBy(10 ** 18)
        const _minimumStake = new BigNumber(minimumStake)
        if (_amountStake.isLessThan(_minimumStake)) {
            return false;
        }
        return true;
    }
    const isButtonUnstakeEnabled = () => {
        if (!amountUnstake) return false;
        const _amountStake = new BigNumber(amountUnstake).multipliedBy(10 ** 18)
        const _maximumUnstake = new BigNumber(stakedBalance.toString()).multipliedBy(10 ** 18)
        if (_amountStake.isGreaterThan(_maximumUnstake)) {
            return false;
        }
        return true;
    }

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
    };

    useEffect(() => {
        if (state.web3 && state.account) {
            initialization();

        }
    }, [state.web3, state.account]);

    return (
        <>
            <Header />
            <section
                className="relative px-4 py-4 overflow-hidden bg-gray-100 lg:px-28 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                <div className="flex flex-col justify-between w-full gap-10 md:flex-row lg:flex-row xl:flex-row">
                    <div className="w-full md:w-7/12 lg:w-7/12 xl:w-7/12">
                        <div className="flex justify-between w-full mb-3">
                            <h1 className="text-lg font-semibold">Stake Sakai</h1>
                            <div className="flex items-center gap-1">
                                <img src="/images/icon-reward/sakai-token.svg" width="20" height="20" alt="" />
                                <div>SAKAI: $0.6210</div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-5">
                            <div className="w-full p-5 bg-white border border-gray-400 rounded-3xl">
                                <div className="flex items-center justify-between w-full">
                                    <div className="flex items-center justify-between w-full gap-3">
                                        <div>
                                            <RiGovernmentLine className="text-3xl" />
                                        </div>
                                        <div>
                                            <h1 className="text-2xl font-semibold">DAO Governance</h1>
                                            <p className="mt-2">SAKAI holders can stake their tokens to vote on
                                                proposals and receive rewards in SAKAI. <a href="/faq"
                                                    className="text-green-500">FAQ
                                                    ↗</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full p-5 bg-white border border-gray-400 rounded-3xl">
                                <div className="flex items-center justify-between w-full gap-4">
                                    <div>
                                        <RiChatPollLine className="text-3xl" />
                                    </div>
                                    <div
                                        className="flex flex-col items-start justify-between w-full gap-4 md:items-center lg:items-center xl:items-center md:flex-row lg:flex-row xl:flex-row">
                                        <div className="flex items-center justify-between w-full gap-3">
                                            <div>
                                                <h1 className="text-2xl font-semibold">Stake + Vote</h1>
                                                <p className="mt-2">The more you stake and vote, the more SAKAI you will
                                                    earn.</p>
                                            </div>
                                        </div>
                                        <a href="/vote"
                                            className="px-10 py-2 text-green-800 bg-green-300 hover:bg-green-400 rounded-2xl">Vote</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-5/12 lg:w-5/12 xl:w-5/12">
                        <Tab.Group>
                            <Tab.List className="flex justify-start w-full gap-10 text-center">
                                <Tab as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected ? 'text-green-500 font-semibold text-xl' : 'text-black font-semibold text-xl'
                                            }
                                        >
                                            Stake
                                        </button>
                                    )}
                                </Tab>
                                <Tab as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected ? 'text-green-500 font-semibold text-xl' : 'text-black font-semibold text-xl'
                                            }
                                        >
                                            Unstake
                                        </button>
                                    )}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel>
                                    <div className="px-4 py-3 mt-5 bg-white rounded-3xl">
                                        <div className="flex items-center justify-between">
                                            <div>Your Sakai Balance</div>
                                            <div className="flex items-center justify-start gap-2">
                                                <img src="/images/icon-reward/sakai-token.svg" width="30" height="30"
                                                    alt="" />
                                                <div>{amountFormat(tokenBalance.toString(), 0)} <strong>SAKAI</strong></div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>Your Staked Sakai</div>
                                            <div className="flex items-center justify-start gap-2">
                                                <img src="/images/icon-reward/sakai-token.svg" width="30" height="30"
                                                    alt="" />
                                                <div>{amountFormat(stakedBalance.toString(), 0)} <strong>POWERS</strong></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 mt-5 bg-white rounded-3xl">
                                        <div className="flex items-center justify-between mb-3">
                                            <a href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x43B35e89d15B91162Dea1C51133C4c93bdd1C4aF"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="flex items-center gap-2 font-semibold text-black hover:text-green-500">
                                                <RiExchangeLine />
                                                Get SAKAI
                                            </a>
                                            <a href="/staking-history"
                                                className="flex items-center gap-1 font-semibold text-black hover:text-green-500">
                                                <RiTimer2Line />
                                                <div>History</div>
                                            </a>
                                        </div>
                                        <div className="px-3 py-2 mb-4 bg-gray-100 rounded-2xl">
                                            <div className="flex justify-between w-full">
                                                <div className="flex gap-1">
                                                    <div
                                                        className="px-3 py-1 text-xs text-black bg-white border border-gray-400 rounded-xl">Max
                                                    </div>
                                                    <div
                                                        className="px-3 py-1 text-xs text-black bg-white border border-gray-400 rounded-xl">Half
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-1">
                                                    <RiWallet2Fill />
                                                    0
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between w-full gap-2 py-3">
                                                <input type="number"
                                                    className="w-8/12 px-3 py-2 bg-white border border-gray-500 rounded-xl"
                                                    onChange={(e) => setAmountStake(e.target.value)}
                                                />
                                                <div className="flex items-center justify-start w-4/12 gap-3">
                                                    <span className="text-sm text-gray-600">
                                                        ~$1.21
                                                    </span>
                                                    <div
                                                        className="flex text-center w-full p-1.5 justify-center bg-white rounded-full">
                                                        <img src="/images/icon-reward/sakai-token.svg" width="20"
                                                            height="20" className="mr-1" alt="sakai" />
                                                        <div>SAKAI</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"flex flex-row justify-between align-middle"}>
                                                <div>Minimum Stake: {amountFormatFromWei(minimumStake, 0)} SAKAI</div>

                                            </div>
                                            <div className={"flex flex- justify-between align-middle"}>
                                                <div>Sakai Address: </div>
                                                <div className={"flex flex-row gap-2 text-blue-500"}>
                                                    <a href={urlToken} target={"_blank"}>{getSimpleAddress(tokenAddress)}</a> <CopyButton text={tokenAddress} />
                                                </div>
                                            </div>
                                            <div className={"flex flex- justify-between align-middle"}>
                                                <div>DAO Address: </div>
                                                <div className={"flex flex-row gap-2 text-blue-500"}>
                                                    <a href={urlStaking} target={"_blank"}>{getSimpleAddress(stakingAddress)}</a> <CopyButton text={stakingAddress} />
                                                </div>
                                            </div>

                                        </div>
                                        {isNeedApproveStake && (
                                            <button
                                                className="flex items-center justify-center w-full px-4 py-2.5 text-center bg-red-400 hover:bg-green-500 rounded-2xl"
                                                type={"button"}
                                                disabled={isLoading}
                                                onClick={handleApproveStaking}
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                                )}
                                                <strong>Approve</strong>
                                            </button>
                                        )}
                                        {!isNeedApproveStake && (
                                            <button
                                                className={`flex items-center justify-center w-full px-4 py-2.5 text-center ${isButtonStakeEnabled() ? 'bg-green-400' : 'bg-green-200'} hover:bg-green-500 rounded-2xl`}
                                                type={"button"}
                                                disabled={isLoading || !isButtonStakeEnabled()}
                                                onClick={() => handleStake()}
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                                )}
                                                <strong>Stake</strong>
                                            </button>
                                        )}

                                    </div>
                                    <div className="px-4 py-4 mt-5 bg-white rounded-2xl">
                                        <h1 className="text-lg font-semibold">Stake Information</h1>
                                        <hr className="my-3" />
                                        <div className="flex flex-col w-full gap-2">
                                            <div className="flex justify-between w-full">
                                                <div>Stake Amount</div>
                                                <div>{amountFormat(stakedBalance.toString(), 0)} SAKAI</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Voting Power</div>
                                                <div>0% → 0.000000218%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-4 mt-5 bg-white rounded-2xl">
                                        <h1 className="text-lg font-semibold">Affiliate Information</h1>
                                        <hr className="my-3" />
                                        <div className="my-3">
                                            <div className="flex items-center justify-start w-full" key={reffCode}>
                                                <div
                                                    className="px-3 py-2 text-black bg-green-300 border rounded-l-md whitespace-nowrap">Affiliate
                                                    Link
                                                </div>
                                                <input type="text"
                                                    className="w-full px-3 py-2 text-black bg-gray-100 border"
                                                    defaultValue={'https://app.sakaivault.io/stake?reff=' + reffCode}
                                                    placeholder={'https://app.sakaivault.io/stake?reff=' + reffCode}
                                                    ref={textAreaRef} />
                                                <button onClick={copyToClipboard}
                                                    className="flex items-center justify-center px-3 py-3 text-black bg-green-400 border rounded-r-md">
                                                    <RiFileCopyLine />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <div className="flex justify-between w-full">
                                                <div>Unclaim Reward From Referral</div>
                                                <div>{amountFormatFromWei(rewardOfReferrer.toString(), 0)} SAKAI</div>
                                            </div>

                                            <div className="flex justify-between w-full">
                                                <div>Total Accumulation Referral</div>
                                                <div>{amountFormatFromWei(totalRewardOfReferrer.toString(),0)} SAKAI</div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className="px-4 py-3 mt-5 bg-white rounded-3xl">
                                        <div className="flex items-center justify-between">
                                            <div>Your Staked Sakai</div>
                                            <div className="flex items-center justify-start gap-2">
                                                <img src="/images/icon-reward/sakai-token.svg" width="30" height="30"
                                                    alt="" />
                                                <div>{amountFormat(stakedBalance.toString(), 0)} POWERS</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>Days Stake</div>
                                            <div className="flex items-center justify-start gap-2">
                                                <img src="/images/icon-reward/sakai-token.svg" width="30" height="30"
                                                     alt="" />
                                                <div>{dayStake} Day</div>
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-between">
                                            <div>Estimation Tax</div>
                                            <div className="flex items-center justify-start gap-2">
                                                <img src="/images/icon-reward/sakai-token.svg" width="30" height="30"
                                                     alt="" />
                                                <div>{estimationTax} %</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-3 mt-5 bg-white rounded-3xl">
                                        <div className="flex items-center justify-between mb-3">
                                            <a href="https://pancakeswap.finance/swap?chain=bsc&outputCurrency=0x43B35e89d15B91162Dea1C51133C4c93bdd1C4aF"
                                                target="_blank"
                                                rel="noreferrer"
                                                className="flex items-center gap-2 font-semibold text-black hover:text-green-500">
                                                <RiExchangeLine />
                                                Get SAKAI
                                            </a>
                                            <a href="/staking-history"
                                                className="flex items-center gap-1 font-semibold text-black hover:text-green-500">
                                                <RiTimer2Line />
                                                <div>History</div>
                                            </a>
                                        </div>
                                        <div className="px-3 py-2 mb-4 bg-gray-100 rounded-2xl">
                                            <div className="flex justify-between w-full">
                                                <div className="flex gap-1">
                                                    <div
                                                        className="px-3 py-1 text-xs text-black bg-white border border-gray-400 rounded-xl">Max
                                                    </div>
                                                    <div
                                                        className="px-3 py-1 text-xs text-black bg-white border border-gray-400 rounded-xl">Half
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between w-full gap-2 py-3">
                                                <input type="number"
                                                    onChange={(e) => setAmountUnstake(e.target.value)}
                                                    className="w-8/12 px-3 py-2 bg-white border border-gray-500 rounded-xl" />
                                                <div className="flex items-center justify-start w-4/12 gap-3">
                                                    <span className="text-sm text-gray-600">
                                                        ~$1.21
                                                    </span>
                                                    <div
                                                        className="flex text-center w-full p-1.5 justify-center bg-white rounded-full">
                                                        <img src="/images/icon-reward/sakai-token.svg" width="20"
                                                            height="20" className="mr-1" alt="sakai" />
                                                        <div>SAKAI</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            className={`flex items-center justify-center w-full px-4 py-2.5 text-center ${isButtonUnstakeEnabled() ? 'bg-red-400' : 'bg-red-200'} hover:bg-green-500 rounded-2xl`}
                                            type={"button"}
                                            disabled={isLoading || !isButtonUnstakeEnabled()}
                                            onClick={() => handleUnstake()}
                                        >
                                            {isLoading && (
                                                <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                    <FaSpinner className='animate-spin' />
                                                </div>
                                            )}

                                            Unstake
                                        </button>
                                    </div>
                                    <div className="px-4 py-4 mt-5 bg-white rounded-2xl">
                                        <h1 className="text-lg font-semibold">Stake Information</h1>
                                        <hr className="my-3" />
                                        <div className="flex flex-col w-full gap-2">
                                            <div className="flex justify-between w-full">
                                                <div>Stake Amount</div>
                                                <div>0.1 SAKAI → 0 SAKAI</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Voting Power</div>
                                                <div>0.000000218% → 0%</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-4 py-4 mt-5 bg-white rounded-2xl">
                                        <h1 className="text-lg font-semibold">Affiliate Information</h1>
                                        <hr className="my-3" />
                                        <div className="my-3">
                                            <div className="flex items-center justify-start w-full">
                                                <div
                                                    className="px-3 py-2 text-black bg-green-300 border rounded-l-md whitespace-nowrap">Affiliate
                                                    Link
                                                </div>
                                                <input type="text"
                                                    className="w-full px-3 py-2 text-black bg-gray-100 border"
                                                    defaultValue={'https://app.sakaivault.io/stake?reff=' + reffCode}
                                                    placeholder={'https://app.sakaivault.io/stake?reff=' + reffCode}
                                                    ref={textAreaRef} />
                                                <button onClick={copyToClipboard}
                                                    className="flex items-center justify-center px-3 py-3 text-black bg-green-400 border rounded-r-md">
                                                    <RiFileCopyLine />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full gap-2">
                                            <div className="flex justify-between w-full">
                                                <div>Stake Refferer Count</div>
                                                <div>0</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Current Reward</div>
                                                <div>100 SAKAI</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Min Rewards</div>
                                                <div>10 SAKAI</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Total Reff Amount</div>
                                                <div>1 SAKAI</div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}