import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./Store";
import Home from "./pages/Home";
import Vote from "./pages/Vote";
import StakingHistory from "./pages/StakingHistory";
import StakingDaoDetail from "./pages/StakingDaoDetail";
import VoteManagement from "./pages/VoteManagement";

export default function Navigation() {
    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                    <Route path={"/vote"} element={<Vote />} />
                    <Route path={"/vote-management"} element={<VoteManagement />} />
                    <Route path={"/staking-history"} element={<StakingHistory />} />
                    <Route path={"/staking-dao-detail/:address"} element={<StakingDaoDetail />} />
                </Routes>
            </BrowserRouter>
        </Store>
    )
}