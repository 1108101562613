import React, { Fragment, useContext, useEffect, useState } from "react";
import { Footer, Header } from "../components/sakai-ui";
import { Disclosure, Tab, RadioGroup, Transition, Dialog } from '@headlessui/react';
import {
    RiArrowUpLine,
    RiChat2Line,
    RiCloseLine,
    RiExternalLinkLine,
    RiInformationLine,
    RiQuestionLine,
    RiSearchLine,
    RiShoppingCartLine,
    RiTimer2Line,
    RiTimerLine
} from "react-icons/ri";
import { formatNumericWithComma } from "../libs/utils";
import { Context } from "../Store";
import { amountFormatFromWei, checkIsNeedApprove, getTokenBalance } from "../libs/WebooLib";
import { getAppByChainId } from "../libs/Env";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const RowVote = ({ proposalId }) => {
    const [state] = useContext(Context);
    const statusText = ['Pending', 'On Going', 'Reject', 'Canceled', 'Finished'];
    let [proposals, setProposals] = useState('yes');
    let [minimumSakai, setMinimumSakai] = useState(25000);
    let [isVoteListModal, setVoteListModal] = useState(false);
    let [isLoading, setIsLoading] = useState(false);
    const [detailProposal, setDetailProposal] = useState({
        title: '',
        description: '',
        start_date: '',
        end_date: '',
        status: '',
    })
    function openModalVote() {
        setVoteListModal(true);
    }

    function closeModalVote() {
        setVoteListModal(false);
    }

    const initialization = () => {
        const contract = async () => {
            try {
                const proposalContract = await new state.web3.eth.Contract(
                    getAppByChainId(state.chainId).PROPOSAL_ABI,
                    getAppByChainId(state.chainId).PROPOSAL_ADDRESS
                );
                const _detailProposal = await proposalContract.methods.proposals(proposalId).call();
                console.log(_detailProposal)
                setDetailProposal(_detailProposal)
            } catch (e) {
                console.error(e)
            }

        }
        contract().then((res) => {

        })
    }

    const isCanApproveAsAdmin = async () => {
        const proposalContract = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).PROPOSAL_ABI,
            getAppByChainId(state.chainId).PROPOSAL_ADDRESS
        );
        const isAdmin = await proposalContract.methods.isAdmin(state.account).call();
        if (!isAdmin) {
            return false;
        }
        return true;
    }

    const isCanApproveAsUser = async () => {
        const stakeContract = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).STAKING_ABI,
            getAppByChainId(state.chainId).STAKING_ADDRESS
        );
        const isCanVote = await stakeContract.methods.isCanVote(state.account).call();
        return isCanVote;
    }

    useEffect(() => {
        if (state.web3 && state.account) {
            initialization()
        }
    }, [state.web3, state.account]);

    const handleApproveAdmin = async (isApprove) => {
        setIsLoading(true)
        const proposalContract = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).PROPOSAL_ABI,
            getAppByChainId(state.chainId).PROPOSAL_ADDRESS
        );
        try {
            await proposalContract.methods.voteAdmin(proposalId, isApprove).send({
                from: state.account
            })
            toast.success('Approve success')
        } catch (e) {
            console.error(e)
            toast.error('Approve failed')
        } finally {
            initialization()
            setIsLoading(false)
        }
    }

    const handleApproveUser = async (isApprove) => {
        setIsLoading(true)
        const proposalContract = await new state.web3.eth.Contract(
            getAppByChainId(state.chainId).PROPOSAL_ABI,
            getAppByChainId(state.chainId).PROPOSAL_ADDRESS
        );
        try {
            await proposalContract.methods.voteUser(proposalId, isApprove).send({
                from: state.account
            })
            toast.success('Approve success')
        } catch (e) {
            console.error(e)
            toast.error('Approve failed')
        } finally {
            initialization()
            setIsLoading(false)
        }
    }

    return (
        <>
            <Disclosure as="div" className="mt-6">
                {({ open }) => (
                    <>
                        <Disclosure.Button
                            className="flex justify-between w-full px-4 py-4 text-sm font-medium text-left text-gray-900 bg-white rounded-2xl hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                            <div className="w-full">
                                <span>SDP-{proposalId}: {detailProposal?.title} - {detailProposal?.description}</span>
                                <div className="flex items-center justify-between w-full mt-4">
                                    <div>Ended 22 Sep 2023</div>
                                    <div className="flex items-center gap-3">
                                        <div className="px-4 py-1 text-sm text-blue-500 bg-blue-100 rounded-2xl">
                                            {statusText[detailProposal?.status]}, Admin Approve: {detailProposal?.adminApproved} / Admin Reject: {detailProposal?.adminRejected}
                                        </div>
                                        <div
                                            className="px-4 py-1 text-sm text-gray-800 bg-gray-300 rounded-2xl">ID
                                            #2
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <RiArrowUpLine
                                className={`${open ? 'rotate-180 transform' : ''
                                    } h-5 w-5 text-gray-900`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pb-4 text-sm text-black bg-white">
                            <div className="flex items-center justify-between w-full gap-6 pt-6">
                                <RadioGroup value={proposals} onChange={setProposals}
                                    className="flex justify-between w-full gap-4">
                                    <RadioGroup.Option value="yes" className="w-full">
                                        {({ checked }) => (
                                            <div
                                                className={checked ? 'flex justify-between w-full px-4 py-2 text-center bg-green-400 rounded-lg' : 'flex justify-between w-full px-4 py-2 text-center bg-green-400 rounded-lg'}>
                                                <div className="flex items-center justify-start gap-2">
                                                    <div className="p-1 border rounded-full">
                                                        <div
                                                            className={checked ? 'p-1.5 bg-white rounded-full' : 'p-1.5 rounded-full'}></div>
                                                    </div>
                                                    0. Yes
                                                </div>
                                                <div>
                                                    14.53%
                                                </div>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                    <RadioGroup.Option value="no" className="w-full">
                                        {({ checked }) => (
                                            <div
                                                className={checked ? 'flex justify-between w-full px-4 py-2 text-center bg-green-400 rounded-lg' : 'flex justify-between w-full px-4 py-2 text-center bg-green-400 rounded-lg'}>
                                                <div className="flex items-center justify-start gap-2">
                                                    <div className="p-1 border rounded-full">
                                                        <div
                                                            className={checked ? 'p-1.5 bg-white rounded-full' : 'p-1.5 rounded-full'}></div>
                                                    </div>
                                                    1. No
                                                </div>
                                                <div>
                                                    85.47%
                                                </div>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                </RadioGroup>
                            </div>
                            <div className="w-full py-4">
                                <div className="flex flex-col items-center justify-between w-full gap-2 mb-5 md:flex-row lg:flex-row xl:flex-row">

                                    {isCanApproveAsAdmin() && (
                                        <>
                                            <button
                                                className={`flex items-center justify-center w-full px-4 py-2.5 text-center ${isCanApproveAsAdmin() ? 'bg-green-400' : 'bg-green-200'} hover:bg-green-500 rounded-2xl`}
                                                type={"button"}
                                                disabled={isLoading}
                                                onClick={() => handleApproveAdmin(true)}
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                                )}
                                                <strong>Approve as Admin</strong>
                                            </button>
                                            <button
                                                className={`flex items-center justify-center w-full px-4 py-2.5 text-center ${isCanApproveAsAdmin() ? 'bg-red-400' : 'bg-red-200'} hover:bg-green-500 rounded-2xl`}
                                                type={"button"}
                                                disabled={isLoading}
                                                onClick={() => handleApproveAdmin(false)}
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                                )}
                                                <strong>Reject as Admin</strong>
                                            </button>
                                        </>
                                    )}
                                </div>
                                <div className="flex flex-col items-center justify-between w-full gap-2 md:flex-row lg:flex-row xl:flex-row">

                                    {isCanApproveAsUser() && (
                                        <>
                                            <button
                                                className={`flex items-center justify-center w-full px-4 py-2.5 text-center ${isCanApproveAsAdmin() ? 'bg-yellow-400' : 'bg-yellow-200'} hover:bg-green-500 rounded-2xl`}
                                                type={"button"}
                                                disabled={isLoading}
                                                onClick={() => handleApproveUser(true)}
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                                )}
                                                <strong>Approve as User</strong>
                                            </button>
                                            <button
                                                className={`flex items-center justify-center w-full px-4 py-2.5 text-center ${isCanApproveAsAdmin() ? 'bg-blue-400' : 'bg-red-200'} hover:bg-blue-500 rounded-2xl`}
                                                type={"button"}
                                                disabled={isLoading}
                                                onClick={() => handleApproveUser(false)}
                                            >
                                                {isLoading && (
                                                    <div className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                        <FaSpinner className='animate-spin' />
                                                    </div>
                                                )}
                                                <strong>Reject as User</strong>
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div
                                className="flex flex-col w-full gap-6 md:flex-row ld:flex-row xl:flex-row">
                                <div className="w-full md:w-8/12 lg:w-8/12 xl:w-8/12">
                                    <a href="https://api.sakaivault.io/cdn/files/sdp.pdf"
                                        className="flex items-center gap-1 text-green-500 hover:text-green-600">
                                        <RiExternalLinkLine /> Open File
                                    </a>
                                    <p className="my-2 font-semibold">
                                        This proposal seeks to configure the current system of SAKAI
                                        rewards distribution such that delegators (SAKAI stakers who
                                        delegate voting power to another address) are able to directly
                                        receive and claim voting rewards proportional to their stake.
                                        With this update, delegators do not need to trust their
                                        delegates to return rewards to them after voting and their
                                        delegates can focus solely on governance and voting operations;
                                        encouraging more SAKAI stakers and delegates in the process.
                                    </p>

                                    <div
                                        className="flex flex-col justify-between w-full gap-8 mt-4 md:flex-row lg:flex-row xl:flex-row">
                                        <div onClick={openModalVote}
                                            className="w-full p-4 bg-white border border-gray-500 md:w-6/12 lg:w-6/12 xl:w-6/12 rounded-2xl hover:border-gray-600">
                                            <div
                                                className="flex items-center justify-between w-full text-lg">
                                                <h1>Yes</h1>
                                                <div>5,084,577</div>
                                            </div>
                                            <hr className="my-3 border " />
                                            <div
                                                className="flex items-center justify-between text-gray-700">
                                                <div>Wallet</div>
                                                <div>Amount</div>
                                            </div>
                                            <hr className="my-3 border " />
                                            <div className="flex flex-col w-full gap-2">
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0x34335354...ee</div>
                                                    <div>1,950,000</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xasd34733...ad</div>
                                                    <div>1,000,000</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xdjf934f4...f1</div>
                                                    <div>733,272</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xdfdcd772...00</div>
                                                    <div>650,000</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xdfrg2d4f...c3</div>
                                                    <div>192,326</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div onClick={openModalVote}
                                            className="w-full p-4 bg-white border border-gray-500 md:w-6/12 lg:w-6/12 xl:w-6/12 rounded-2xl hover:border-gray-600">
                                            <div
                                                className="flex items-center justify-between w-full text-lg">
                                                <h1>No</h1>
                                                <div>29,900,531</div>
                                            </div>
                                            <hr className="my-3 border " />
                                            <div
                                                className="flex items-center justify-between text-gray-700">
                                                <div>Wallet</div>
                                                <div>Amount</div>
                                            </div>
                                            <hr className="my-3 border " />
                                            <div className="flex flex-col w-full gap-2">
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xhd3h4338...1f</div>
                                                    <div>10,966,054</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xticvd455...k1</div>
                                                    <div>6,862,477</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0x23cdfaa2...55</div>
                                                    <div>5,200,000</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0xaccsd223...2a</div>
                                                    <div>1,950,000</div>
                                                </div>
                                                <div
                                                    className="flex items-center justify-between text-gray-700">
                                                    <div>0x9433ddd3...25</div>
                                                    <div>1,637,500</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full md:w-4/12 lg:w-4/12 xl:w-4/12">
                                    <div className="p-6 bg-white border border-gray-400 rounded-3xl">
                                        <h1 className="font-semibold">Vote Information</h1>
                                        <hr className="my-3" />
                                        <div className="flex flex-col gap-2">
                                            <div className="flex justify-between w-full">
                                                <div>Voting System</div>
                                                <div>GenericProposal</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Start Date</div>
                                                <div>15 September 2023</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>End Date</div>
                                                <div>22 September 2023</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Total Addresses</div>
                                                <div>45</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Sakai Amount</div>
                                                <div>34,984,448</div>
                                            </div>
                                            <div className="flex justify-between w-full">
                                                <div>Your SDP Voting Power</div>
                                                <div>0%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* Modal Vote Address List */}
            <Transition appear show={isVoteListModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModalVote}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    className="w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        <div className="flex justify-between w-full" onClick={closeModalVote}>
                                            List Address Votes
                                            <RiCloseLine />
                                        </div>
                                    </Dialog.Title>
                                    <div className="mt-2">
                                        <div
                                            className="w-full p-4 bg-white border border-gray-500 rounded-2xl hover:border-gray-600">
                                            <div className="flex items-center justify-between w-full text-lg">
                                                <h1>Yes</h1>
                                                <div>5,084,577</div>
                                            </div>
                                            <hr className="my-3 border " />
                                            <div className="flex items-center justify-between text-gray-700">
                                                <div>Wallet</div>
                                                <div>Amount</div>
                                            </div>
                                            <hr className="my-3 border " />
                                            <div className="w-full h-3/6">
                                                <div className="flex flex-col w-full gap-2 overflow-y-auto">
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0x34335354...ee</div>
                                                        <div>1,950,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xasd34733...ad</div>
                                                        <div>1,000,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdjf934f4...f1</div>
                                                        <div>733,272</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdfdcd772...00</div>
                                                        <div>650,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdfrg2d4f...c3</div>
                                                        <div>192,326</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0x34335354...ee</div>
                                                        <div>1,950,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xasd34733...ad</div>
                                                        <div>1,000,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdjf934f4...f1</div>
                                                        <div>733,272</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdfdcd772...00</div>
                                                        <div>650,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdfrg2d4f...c3</div>
                                                        <div>192,326</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0x34335354...ee</div>
                                                        <div>1,950,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xasd34733...ad</div>
                                                        <div>1,000,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdjf934f4...f1</div>
                                                        <div>733,272</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdfdcd772...00</div>
                                                        <div>650,000</div>
                                                    </div>
                                                    <div
                                                        className="flex items-center justify-between text-gray-700">
                                                        <div>0xdfrg2d4f...c3</div>
                                                        <div>192,326</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            {/* End Modal Vote Address List */}
        </>
    )
}

export default function Vote() {
    const [state] = useContext(Context);
    // Notes: All modal on bottom of this code, on top only state for trigger the modals

    // Status on SDP (Sakai Development Proposals):
    // 1. Active,
    // 2. Pending,
    // 3. Approved,
    // 4. Executed,
    // 5. Failed,
    // 6. Canceled

    // Default YES OR NO in vote on disclosure (DROPDOWN)


    // For Modal History
    let [isHistoryModal, setHistoryModal] = useState(false);
    // For list address who already votes

    let [minimumSakai, setMinimumSakai] = useState(25000);
    // For Modal buy sakai, if investor sakai stake balance < 25,000
    let [isBuySakaiModal, setBuySakaiModal] = useState(false);
    // For Modal Create Sakai development proposals, if investor sakai stake balannce > 25,000
    let [isVoteCreateModal, setVoteCreateModal] = useState(false);
    // For state sakai minimum, change to under 25,000 the create proposals button will be display different modals

    // For state sakai reward can be claim
    let [canClaimSakai, setCanClaimSakai] = useState(0);
    // For state sakai reward already claimed
    let [claimedSakai, setClaimedSakai] = useState(25000);
    // For state total staked sakai
    let [totalStakedSakai, setTotalStakedSakai] = useState(45854959);
    // For state total voting reward
    let [totalVotingReward, setTotalVotingReward] = useState(0);
    // For state voting power in percentage
    let [votingPower, setVotingPower] = useState(0);
    let [minimumCreateProposal, setMinimumCreateProposal] = useState(0);
    let [isCanCreateProposal, setCanCreateProposal] = useState(false);
    let [proposalData, setProposalData] = useState({
        subject: '',
        description: '',
        start_date: '',
        end_date: '',
        category: '',
        file: '',
    });
    let [isLoading, setIsLoading] = useState(false);
    let [lastProposalId, setLastProposalId] = useState(0);

    function closeModal() {
        setHistoryModal(false);
    }

    function openModal() {
        setHistoryModal(true);
    }




    function closeModalBuySakai() {
        setBuySakaiModal(false);
    }

    function openModalBuySakai() {
        setBuySakaiModal(true);
    }

    function closeModalVoteCreate() {
        setVoteCreateModal(false);
    }

    function openModalVoteCreate() {
        setVoteCreateModal(true);
    }

    const initialization = () => {
        const contract = async () => {
            try {
                const stakingContract = await new state.web3.eth.Contract(
                    getAppByChainId(state.chainId).STAKING_ABI,
                    getAppByChainId(state.chainId).STAKING_ADDRESS
                );
                const totalSupply = await stakingContract.methods.totalSupply().call();
                const stakedBalance = await stakingContract.methods.balanceOf(state.account).call();
                setTotalStakedSakai(
                    state.web3.utils.fromWei(totalSupply)
                );
                const percentage = (stakedBalance / totalSupply) * 100;
                setVotingPower(percentage)

                const dividendOf = await stakingContract.methods.dividendOf(state.account).call();
                setCanClaimSakai(dividendOf)

                const minimumStakeForCreateProposal = await stakingContract.methods.minimumStakeForCreateProposal().call();
                setMinimumCreateProposal(minimumStakeForCreateProposal)

                const proposalContract = await new state.web3.eth.Contract(
                    getAppByChainId(state.chainId).PROPOSAL_ABI,
                    getAppByChainId(state.chainId).PROPOSAL_ADDRESS
                );
                const lastProposal = await proposalContract.methods.proposalId().call();
                setLastProposalId(lastProposal)
            } catch (e) {
                console.error(e)
            }

        }
        contract().then((res) => {

        })
    }

    const createProposal = async (e) => {
        try {
            setIsLoading(true)
            const proposalContract = await new state.web3.eth.Contract(
                getAppByChainId(state.chainId).PROPOSAL_ABI,
                getAppByChainId(state.chainId).PROPOSAL_ADDRESS
            );
            const tx = await proposalContract.methods.createProposal(
                proposalData.subject,
                proposalData.description,
            ).send({
                from: state.account,
            })
            toast.success('Create Proposal Success')
        } catch (e) {
            console.error(e)
            toast.error('Create Proposal Failed')
        } finally {
            setIsLoading(false)
        }
    }


    useEffect(() => {
        if (state.web3 && state.account) {
            initialization()
        }
    }, [state.web3, state.account]);

    return (
        <>
            <Header />
            <section
                className="relative px-4 py-4 overflow-hidden bg-gray-100 lg:px-28 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                <div className="flex justify-between w-full">
                    <h1 className="text-xl font-semibold">Vote - Earn Rewards</h1>
                    <div className="flex gap-3 flex-cols md:flex-row lg:flex-row xl:flex-row">
                        {minimumSakai === 25000 ? (
                            <button className="px-4 py-2 bg-green-300 rounded-3xl hover:bg-green-400"
                                onClick={openModalVoteCreate}>Create Proposals</button>
                        ) : (
                            <button className="px-4 py-2 bg-green-300 rounded-3xl hover:bg-green-400"
                                onClick={openModalBuySakai}>Create Proposals</button>
                        )}
                        <div className="flex items-center gap-1">
                            <img src="/images/icon-reward/sakai-token.svg" width="20" height="20" alt="" />
                            <div>SAKAI: $0.6210</div>
                        </div>
                    </div>
                </div>

                <div
                    className="grid w-full grid-cols-1 gap-4 mt-3 md:gap-8 ld:gap-8 xl:gap-8 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4">
                    <div className="w-full p-5 bg-white rounded-2xl">
                        <div className="text-gray-700">
                            Total Staked SAKAI
                        </div>
                        <h1 className="my-2 text-xl font-semibold text-black">
                            {formatNumericWithComma(totalStakedSakai)} SAKAI
                        </h1>
                        <h5 className="text-sm">
                            ~28,478,085 USD
                        </h5>
                    </div>
                    <div className="w-full p-5 bg-white rounded-2xl">
                        <div className="text-gray-700">
                            Total Voting Rewards
                        </div>
                        <h1 className="my-2 text-xl font-semibold text-black">
                            {formatNumericWithComma(totalVotingReward)} SAKAI
                        </h1>
                        <h5 className="text-sm">
                            ~29,864 USD
                        </h5>
                    </div>
                    <div className="w-full p-5 bg-white rounded-2xl">
                        <div className="text-gray-700">
                            Your Voting Power
                        </div>
                        <h1 className="my-2 text-xl font-semibold text-black">
                            {votingPower}%
                        </h1>
                        <h5 className="flex justify-between w-full text-sm">
                            <div>
                                --
                            </div>
                            <a href="/" className="text-green-500">
                                Stake SAKAI ↗
                            </a>
                        </h5>
                    </div>
                    <div className="w-full p-5 bg-white rounded-2xl">
                        <Tab.Group>
                            <Tab.List className="flex gap-3">
                                <Tab as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected ? 'text-green-500' : 'text-gray-700'
                                            }
                                        >
                                            Your Reward
                                        </button>
                                    )}
                                </Tab>
                                <Tab as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected ? 'text-green-500' : 'text-gray-700'
                                            }
                                        >
                                            Claimed Reward
                                        </button>
                                    )}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel>
                                    <div className="flex items-center justify-between w-full">
                                        <div>
                                            <h1 className="my-2 text-xl font-semibold text-black">
                                                {canClaimSakai} SAKAI
                                            </h1>
                                            <h5 className="text-sm">
                                                0 USD
                                            </h5>
                                        </div>
                                        {canClaimSakai > 0 ? (
                                            <button
                                                className="px-4 py-2 bg-green-300 hover:bg-green-400 rounded-3xl">Claim</button>
                                        ) : (
                                            <button className="px-4 py-2 bg-gray-300 rounded-3xl">Claim</button>
                                        )}
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className="flex items-center justify-between w-full">
                                        <div>
                                            <h1 className="my-2 text-xl font-semibold text-black">
                                                {claimedSakai} SAKAI
                                            </h1>
                                            <h5 className="text-sm">
                                                0 USD
                                            </h5>
                                        </div>
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>

                <div className="my-4">
                    <div
                        className="flex flex-col items-start gap-2 mb-1 md:items-center lg:items-center xl:items-center md:flex-row lg:flex-row xl:flex-row">
                        In Progress: Epoch 63
                        <span className="p-1 px-3 text-xs text-center text-green-500 bg-green-200 rounded-xl">
                            4 days 0h 20m 51s
                        </span>
                        Vote on current epoch proposals to get your full reward.
                    </div>
                    <p className="italic text-gray-600">
                        Note: Voting on SAKAIDAO is only available for stake with minimum 25,000 SAKAI
                    </p>
                </div>

                <div className="my-4">
                    <div className="flex items-center justify-between w-full">
                        <h1 className="text-lg font-semibold text-green-500">SDPs</h1>
                        <div className="flex gap-8">
                            <a href="#/" onClick={openModal} className="flex items-center gap-1"><RiTimer2Line /> History</a>
                            <a href="https://t.me/SakaiVault"
                                className="flex items-center gap-1"><RiChat2Line /> Forum</a>
                            <a href="https://docs.sakaivault.io"
                                className="flex items-center gap-1"><RiQuestionLine /> FAQ</a>
                        </div>
                    </div>
                </div>

                <div className="my-4">
                    <div className="flex flex-col justify-between w-full gap-2 md:flex-row lg:flex-row xl:flex-row">
                        <select name="" className="px-5 py-2 bg-white rounded-2xl" id="">
                            <option value="">All</option>
                            <option value="">Pending</option>
                            <option value="">Active</option>
                            <option value="">Approved</option>
                            <option value="">Executed</option>
                            <option value="">Failed</option>
                            <option value="">Canceled</option>
                        </select>

                        <div className="flex">
                            <input type="text" className="px-5 py-2 bg-white rounded-l-2xl"
                                placeholder="Search Proposals" />
                            <div className="flex items-center justify-center px-3 py-2 bg-white rounded-r-2xl">
                                <RiSearchLine />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full" key={lastProposalId}>
                    {/* looping by last proposal id*/}
                    {Array.from({ length: lastProposalId }, (_, i) => (
                        <div key={i}>
                            <RowVote proposalId={i + 1} />
                        </div>
                    ))}

                </div>

                {/* Modal History */}
                <Transition appear show={isHistoryModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-full p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className="w-full max-w-5xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            <div className="flex items-center justify-between w-full">
                                                Your Transaction
                                                <RiCloseLine onClick={closeModal} />
                                            </div>
                                        </Dialog.Title>
                                        <div className="w-full mt-4">
                                            <table className="w-full overflow-x-scroll">
                                                <thead className="p-2 text-white bg-gray-900 rounded-md">
                                                    <tr>
                                                        <td className="p-2">Txn Hash</td>
                                                        <td className="p-2">Action</td>
                                                        <td className="p-2">Local Time</td>
                                                        <td className="p-2">Amount</td>
                                                    </tr>
                                                </thead>
                                                <tbody className="p-2 text-black bg-gray-200 rounded-md">
                                                    <tr>
                                                        <td className="p-2">0x</td>
                                                        <td className="p-2"><a href="">Check</a></td>
                                                        <td className="p-2">21 September 2023</td>
                                                        <td className="p-2">10,000 SAKAI</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="w-full">
                                            <div
                                                className="flex flex-col items-center justify-center w-full py-12 text-center">
                                                <RiInformationLine className="text-5xl" />
                                                <h1>You have no Transaction History</h1>
                                                <div>Go to <a href="/" className="text-green-500">Stake</a></div>
                                            </div>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
                {/* End Modal History */}


                {/* Modal Create SDP - Sakai Development Proposals */}
                <Transition appear show={isVoteCreateModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModalVoteCreate}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-full p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="fixed inset-0 w-screen overflow-y-auto">
                                        <div className="flex items-center justify-center min-h-full p-4">
                                            <Dialog.Panel
                                                className="w-full max-w-3xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                    <div className="flex items-center justify-between w-full">
                                                        Create Sakai Development Proposals
                                                        <RiCloseLine onClick={closeModalVoteCreate} />
                                                    </div>
                                                </Dialog.Title>
                                                <div className="mt-2">
                                                    <div className="p-2 text-sm text-white bg-blue-400 rounded-2xl">
                                                        Minimum Powers For create Proposals
                                                        is: {amountFormatFromWei(minimumCreateProposal, 0)} POWERS
                                                    </div>
                                                    {!isCanCreateProposal && (
                                                        <div
                                                            className="p-2 mt-2 text-sm text-white bg-red-400 rounded-2xl">
                                                            Your Voting Power is not enough to create Proposal.
                                                        </div>
                                                    )}

                                                    <form className="mt-6">
                                                        <span className="w-full">
                                                            <label for="subject"
                                                                className="block text-xs font-semibold text-gray-600 uppercase">Subject</label>
                                                            <input
                                                                id="subject" type="text" name="subject"
                                                                placeholder="Proposals Subject"
                                                                autocomplete="given-name"
                                                                className="block w-full p-3 mt-2 mb-4 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                                                                required
                                                                onChange={(e) => setProposalData({
                                                                    ...proposalData,
                                                                    subject: e.target.value
                                                                })}
                                                            />
                                                        </span>
                                                        <span className="w-full">
                                                            <label for="description"
                                                                className="block text-xs font-semibold text-gray-600 uppercase">Description</label>
                                                            <textarea onChange={(e) => setProposalData({
                                                                ...proposalData,
                                                                description: e.target.value
                                                            })} name="description" id="description"
                                                                className="block w-full p-3 mt-2 mb-4 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                                                                cols="30" rows="10"></textarea>
                                                        </span>
                                                        <span className="w-full">
                                                            <label for="start_date"
                                                                className="block text-xs font-semibold text-gray-600 uppercase">Start Date</label>
                                                            <input id="start_date" type="date" name="start_date"
                                                                placeholder="" autocomplete="given-name"
                                                                className="block w-full p-3 mt-2 mb-4 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                                                                onChange={(e) => setProposalData({
                                                                    ...proposalData,
                                                                    start_date: e.target.value
                                                                })}
                                                            />
                                                        </span>
                                                        <span className="w-full">
                                                            <label for="end_date"
                                                                className="block text-xs font-semibold text-gray-600 uppercase">End Date</label>
                                                            <input id="end_date" type="date" name="end_date"
                                                                placeholder="" autocomplete="given-name"
                                                                className="block w-full p-3 mt-2 mb-4 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                                                                onChange={(e) => setProposalData({
                                                                    ...proposalData,
                                                                    end_date: e.target.value
                                                                })}
                                                            />
                                                        </span>
                                                        <span className="w-full">
                                                            <label for="category"
                                                                className="block text-xs font-semibold text-gray-600 uppercase">Proposals Category</label>
                                                            <select id="category" type="date" name="category"
                                                                placeholder="" autocomplete="given-name"
                                                                className="block w-full p-3 mt-2 mb-4 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner"
                                                                onChange={(e) => setProposalData({
                                                                    ...proposalData,
                                                                    category: e.target.value
                                                                })}
                                                            >
                                                                <option
                                                                    value="communityproposals">Community Proposals</option>
                                                                {/* <option value="developerproposals">Developer Proposals</option> */}
                                                            </select>
                                                        </span>
                                                        {/*<span className="w-full">*/}
                                                        {/*    <label for="proposals_file" className="block text-xs font-semibold text-gray-600 uppercase">Proposals File</label>*/}
                                                        {/*    <input id="proposals_file" type="file" accept="pdf" name="proposals_file" placeholder="" className="block w-full p-3 mt-2 mb-4 text-gray-700 bg-gray-200 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" />*/}
                                                        {/*    <span className="text-sm italic text-red-500">Only PDF file can be accepted</span>*/}
                                                        {/*</span>*/}
                                                        <button
                                                            type="button"
                                                            className="w-full py-3 mt-6 tracking-widest text-white bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none"
                                                            onClick={createProposal}
                                                        >
                                                            {isLoading && (
                                                                <div
                                                                    className='flex flex-row justify-center gap-2 mr-2 align-center'>
                                                                    <FaSpinner className='animate-spin' />
                                                                </div>
                                                            )}
                                                            Create Proposals
                                                        </button>
                                                    </form>
                                                </div>

                                                <div className="mt-4">
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={closeModalVoteCreate}
                                                    >
                                                        Got it, thanks!
                                                    </button>
                                                </div>
                                            </Dialog.Panel>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
                {/* End Modal Create SDP - Sakai Development Proposals */}

                {/* Modal Buy Sakai */}
                <Transition appear show={isBuySakaiModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModalBuySakai}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-full p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <div className="fixed inset-0 w-screen overflow-y-auto">
                                        <div className="flex items-center justify-center min-h-full p-4">
                                            <Dialog.Panel
                                                className="w-full max-w-3xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                                <Dialog.Title
                                                    as="h3"
                                                    className="text-lg font-medium leading-6 text-gray-900"
                                                >
                                                    <div className="flex items-center justify-between w-full">
                                                        Buy Sakai
                                                        <RiCloseLine onClick={closeModalBuySakai} />
                                                    </div>
                                                </Dialog.Title>
                                                <div className="mt-8">
                                                    <div>You need at least <b>25,000 Sakai</b> and stake, for eligible
                                                        create <b>SDP (Sakai Development Proposals)</b></div>
                                                </div>

                                                <div className="flex justify-between mt-4">
                                                    <div className="flex items-center justify-start gap-2">
                                                        <a
                                                            href="https://pancakeswap.finance/swap"
                                                            className="inline-flex items-center justify-center gap-1 px-4 py-2 text-sm font-medium text-green-900 bg-green-200 border border-transparent rounded-md hover:bg-green-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                                                        >
                                                            <RiShoppingCartLine /> Buy Sakai
                                                        </a>
                                                        <a
                                                            href="/"
                                                            className="inline-flex items-center justify-center gap-1 px-4 py-2 text-sm font-medium text-gray-900 bg-gray-200 border border-transparent rounded-md hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                                                        >
                                                            <RiShoppingCartLine /> Stake SAKAI DAO
                                                        </a>
                                                    </div>
                                                    <a
                                                        href="https://pancakeswap.finance/swap"
                                                        className="inline-flex items-center justify-center gap-1 px-4 py-2 text-sm font-medium text-gray-900 bg-gray-200 border rounded-md hover:bg-gray-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                                                    >
                                                        <RiInformationLine /> Read Docs
                                                    </a>
                                                </div>
                                            </Dialog.Panel>
                                        </div>
                                    </div>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
                {/* End Modal Buy Sakai */}
            </section>
            <Footer />
        </>
    )
}