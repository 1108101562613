import React, { useEffect } from 'react'
import { Footer, Header } from '../components/sakai-ui'
import { NavLink } from 'react-router-dom'
import axios from 'axios';

function StakingHistory() {
    const [validatorList, setValidatorList] = React.useState([])

    const getValidators = () => {
        axios.get("https://api.webooswap.com/sakai-dao/api/validators")
            .then(res => {
                // console.log(res.data.data);
                setValidatorList(res.data.data);
            })
            .catch(err => {
                console.error(err);
            });
    }

    useEffect(() => {
        getValidators();
    }, []);
    return (
        <>
            <Header />
            <section className="relative px-4 py-4 overflow-hidden bg-gray-100 lg:px-28 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                <h1 className='text-3xl font-semibold'>Staking DAO History</h1>
                <p className='my-5'>Here information about user already participate Sakai DAO program</p>

                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="bg-white dark:bg-gray-800">
                        <tr>
                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Validators
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Voting Power / %
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Stake Amount
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Reff Commission
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Accumulation
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Stake Date
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                APR
                            </th>

                            <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-800 dark:text-gray-400">
                                Status Vote
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                        {validatorList.map((validator, index) => {
                            return (
                                <tr key={index}>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        <div className="flex items-center gap-x-2">
                                            <img className="object-cover w-8 h-8 rounded-full" src={'https://api.webooswap.com/sakai-dao/profile?address=' + validator.address} alt="" />
                                            <NavLink to={'/staking-dao-detail/' + validator.address}>
                                                <h2 className="text-sm font-medium text-gray-800 dark:text-white ">{validator.address}</h2>
                                            </NavLink>
                                        </div>
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{validator.powers}</td>
                                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{validator.amount}</td>
                                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{validator.ref_commision}</td>
                                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{validator.accumulation}</td>
                                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{validator.last_stake_date}</td>
                                    <td className="px-4 py-4 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{validator.apr}</td>
                                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                                        <span className='px-4 py-1 text-green-500 bg-green-100 rounded-lg'>
                                            {validator.status_vote}
                                        </span>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </section>
            <Footer />
        </>
    )
}

export default StakingHistory