import React, { Fragment, useEffect, useState } from 'react'
import { Footer, Header } from '../components/sakai-ui'
import { Tab } from '@headlessui/react'
import ReactApexChart from 'react-apexcharts';
import { RiArrowLeftLine, RiCalendar2Line, RiVipCrown2Line } from 'react-icons/ri';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';


function StakingDaoDetail() {
    let { address } = useParams();

    const AccumulationChart = () => {
        const [chartData, setChartData] = useState({
            series: [{
                name: 'series1',
                data: [31, 40, 28, 51, 42, 109, 100]
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        });
        return (
            <div id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
            </div>
        );
    }

    const ReffCommisionChart = () => {
        const [chartData, setChartData] = useState({
            series: [{
                name: 'series1',
                data: [12, 32, 90, 100, 102, 109, 130]
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        });

        useEffect(() => {
            axios.get(`https://api.webooswap.com/sakai-dao/api/referral-reward-history/${address}?limit=10`)
                .then(response => {
                    const data = response.data.data;
                    const seriesData = data.map(item => item.amount_in_ether);
                    const categoriesData = data.map(item => new Date(item.timestamp * 1000).toISOString());

                    setChartData(prevState => ({
                        ...prevState,
                        series: [{
                            name: 'series1',
                            data: seriesData
                        }],
                        options: {
                            ...prevState.options,
                            xaxis: {
                                ...prevState.options.xaxis,
                                categories: categoriesData
                            }
                        }
                    }));
                })
                .catch(error => {
                    console.log(error);
                });
        }, [address]);
        return (
            <div id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
            </div>
        );
    }

    const RewardCommisionChart = () => {
        const [chartData, setChartData] = useState({
            series: [{
                name: 'series1',
                data: [12, 32, 90, 100, 102, 109, 130]
            }],
            options: {
                chart: {
                    height: 350,
                    type: 'area'
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'smooth'
                },
                xaxis: {
                    type: 'datetime',
                    categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        });
        useEffect(() => {
            axios.get(`https://api.webooswap.com/sakai-dao/api/stake-reward-history/${address}?limit=10`)
                .then(response => {
                    const data = response.data.data;
                    const seriesData = data.map(item => item.amount_in_ether);
                    const categoriesData = data.map(item => new Date(item.timestamp * 1000).toISOString());

                    setChartData(prevState => ({
                        ...prevState,
                        series: [{
                            name: 'series1',
                            data: seriesData
                        }],
                        options: {
                            ...prevState.options,
                            xaxis: {
                                ...prevState.options.xaxis,
                                categories: categoriesData
                            }
                        }
                    }));
                })
                .catch(error => {
                    console.log(error);
                });
        }, [address]);
        return (
            <div id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
            </div>
        );
    }

    return (
        <>
            <Header />
            <section className="relative px-4 py-4 overflow-hidden bg-gray-100 lg:px-28 dark:bg-gray-900 md:py-20 lg:py-16 font-body">
                <div className="flex items-center justify-between w-full p-8 bg-white rounded-lg">
                    <div className='flex items-start gap-4'>
                        <img src={'https://api.webooswap.com/sakai-dao/profile?address=' + address} width="60px" height="60px" alt="avatar" />
                        <div>
                            <h1 className='text-lg font-semibold'>{address}</h1>
                        </div>
                    </div>

                    <div>
                        <NavLink to="/staking-history" className='flex items-center gap-2 text-green-600'><RiArrowLeftLine /> Back to List</NavLink>
                    </div>
                </div>

                <div className="flex items-start w-full gap-6 my-6">
                    <div className='w-6/12 p-5 bg-white rounded-lg'>
                        <div className="flex flex-col gap-8">
                            <div className="flex flex-col gap-2">
                                <div className="text-gray-700">Voting Power</div>
                                <div className="font-semibold">1,118,446.40901873 SAKAI</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-gray-700">Stake Amount</div>
                                <div className="font-semibold">1,120,446.40901873 SAKAI</div>
                            </div>
                            <div className="flex justify-between w-full">
                                <div className="flex flex-col gap-2">
                                    <div className="text-gray-700">Reff Commission</div>
                                    <div className="font-semibold">10000 Sakai</div>
                                </div>
                                <div className="flex flex-col gap-2">
                                    <div className="text-gray-700">APR</div>
                                    <div className="font-semibold">122%</div>
                                </div>
                            </div>
                            <hr />
                            <div className="flex items-center justify-between">
                                <div className="text-gray-700">Status</div>
                                <span className='px-4 py-1 text-green-500 bg-green-100 rounded-lg'>
                                    Active
                                </span>
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="text-gray-700">Stake Date</div>
                                <div className='flex items-center gap-1'>
                                    <RiCalendar2Line />
                                    31 Sept, 2023 - 21:13:06
                                </div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-gray-700">Reward</div>
                                <div className="font-semibold">2000 Sakai</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-gray-700">PNL</div>
                                <div className="font-semibold">3000 SAKAI</div>
                            </div>
                            <div className="flex flex-col gap-2">
                                <div className="text-gray-700">Wallet Address</div>
                                <div className="font-semibold">0x111111111111111111111111111</div>
                            </div>
                        </div>
                    </div>
                    <div className='w-6/12 p-5 bg-white rounded-lg'>
                        <Tab.Group>
                            <Tab.List className="flex gap-4">
                                <Tab as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected ? 'bg-green-500 py-2 px-6 rounded-lg text-white' : 'bg-gray-300 py-2 px-6 rounded-lg text-gray-900'
                                            }
                                        >
                                            Reward
                                        </button>
                                    )}
                                </Tab>
                                <Tab as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected ? 'bg-green-500 py-2 px-6 rounded-lg text-white' : 'bg-gray-300 py-2 px-6 rounded-lg text-gray-900'
                                            }
                                        >
                                            Reff Commission
                                        </button>
                                    )}
                                </Tab>
                            </Tab.List>
                            <Tab.Panels className="mt-4">
                                <Tab.Panel>
                                    <RewardCommisionChart />
                                </Tab.Panel>
                                <Tab.Panel>
                                    <ReffCommisionChart />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default StakingDaoDetail;